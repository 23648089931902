import { Component } from "react";
import styled from "styled-components";

import RitualButton from "../global/RitualButton";
import Text from "../Text";

// Utils
import { Color, Font, rem, Opacity } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import metrics from "../../utils/metrics";

const DisabledButton = styled.span`
  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.white};
  cursor: default;

  box-sizing: border-radius;
  padding: 0 30px;
  margin: 0;
  border-radius: 26px;
  height: 50px;
  background-color: rgba(20, 43, 111, ${Opacity.light});
  transition: all 0.2s;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  svg {
    width: 13px;
    height: 9px;
    margin-right: 8px;

    g {
      fill: ${Color.white};
    }
  }

  &.primary-cta {
    background-color: ${Color.white};
    color: ${Color.ritualBlue};

    svg {
      g {
        fill: ${Color.ritualBlue};
      }
    }
  }
`;

export default class VerifyButton extends Component {
  constructor(props) {
    super(props);

    const { scope = "military", slug } = props;

    let params = "";
    params += `client_id=${process.env.GATSBY_IDME_CLIENT_ID}`;
    const redirectUri = `${process.env.GATSBY_URL}/${slug}`;
    params += `&redirect_uri=${redirectUri}`;
    params += `&response_type=token`;

    if (scope.includes(",")) {
      params += `&scopes=${scope}`;
      this.buttonUrl = "https://groups.id.me/?" + params;
    } else {
      params += `&scope=${scope}`;
      this.buttonUrl = "https://api.id.me/oauth/authorize?" + params;
    }
  }

  trackCtaClick(eventDetails, url, e) {
    e.preventDefault();
    if (!eventDetails) {
      this.updateUrl(url);
      return;
    }

    // Ensure that we report the "CTA Clicked" metric before navigating away
    // from the page.
    metrics.track("CTA Clicked", eventDetails).then(() => {
      this.updateUrl(url);
    });
  }

  updateUrl(url) {
    window.location.href = url;
  }

  render() {
    const { className, copy, verified, eventDetails } = this.props;
    const { buttonUrl } = this;

    if (!verified) {
      return (
        <RitualButton
          onClick={(e) => this.trackCtaClick(eventDetails, buttonUrl, e)}
          className={className}
        >
          {copy}
        </RitualButton>
      );
    }

    return (
      <DisabledButton className={className}>
        <Icons.CheckRounded />{" "}
        <Text id="discount.verify-button" defaultMessage="Verified" />
      </DisabledButton>
    );
  }
}
